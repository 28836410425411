"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiUtils = require("@tencent/spaui-utils");

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default() {
  var _classNames;

  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var html = props.html,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      _props$valueAreaStyle = props.valueAreaStyle,
      valueAreaStyle = _props$valueAreaStyle === void 0 ? {} : _props$valueAreaStyle,
      _props$valueAreaClass = props.valueAreaClassName,
      valueAreaClassName = _props$valueAreaClass === void 0 ? '' : _props$valueAreaClass,
      _props$labelFieldName = props.labelFieldName,
      labelFieldName = _props$labelFieldName === void 0 ? '' : _props$labelFieldName,
      propsValueLabelField = props.valueLabelField,
      placeholder = props.placeholder,
      value = props.value,
      setValueLabel = props.setValueLabel,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      labelData = props.labelData,
      valuePrefix = props.valuePrefix,
      valueSuffix = props.valueSuffix,
      valueAddons = props.valueAddons;

  var _getTheContentOfTheVa = (0, _index.getTheContentOfTheValueAreaComponent)(props),
      onClear = _getTheContentOfTheVa.onClear,
      arrowDom = _getTheContentOfTheVa.arrowDom,
      onValueAreaClick = _getTheContentOfTheVa.onValueAreaClick,
      showClearIcon = _getTheContentOfTheVa.showClearIcon;

  var valueLabelField = [];

  if (typeof propsValueLabelField === 'undefined') {
    valueLabelField = [labelFieldName, 'mark', 'description'];
  } else {
    valueLabelField = propsValueLabelField;
  } // 根据值获取对应的文案


  var getValueLabel = function getValueLabel(currentValue) {
    var valueData = (0, _index.getCurrentDataForValueHolder)(data, labelData, currentValue) || {};
    var haveLabel = typeof valueData[labelFieldName] !== 'undefined';
    var label = haveLabel ? valueData[labelFieldName] : '';
    var result = ''; // 选项数据结构为对象

    if (_typeof(valueData) === 'object' && haveLabel) {
      // 单选的是否要进行html
      if (!html) {
        result = _react["default"].createElement("span", {
          className: "spaui-selection-item tt"
        }, valueLabelField.map(function (fieldName, index) {
          if (typeof valueData[fieldName] !== 'undefined' && fieldName === labelFieldName) {
            return _react["default"].createElement("span", {
              className: "spaui-selection-item-content in",
              key: index
            }, valueData[fieldName]);
          }

          if (typeof valueData[fieldName] !== 'undefined' && fieldName === 'mark') {
            return _react["default"].createElement("i", {
              className: "tt-remarks",
              key: index
            }, valueData[fieldName]);
          }

          if (typeof valueData[fieldName] !== 'undefined') {
            return _react["default"].createElement("span", {
              key: index
            }, valueData[fieldName]);
          }
        }));
      } else {
        result = _react["default"].createElement("span", {
          className: "spaui-selection-item tt"
        }, valueLabelField.map(function (fieldName, index) {
          if (typeof valueData[fieldName] !== 'undefined' && fieldName === labelFieldName) {
            return _react["default"].createElement("span", {
              className: "spaui-selection-item-content in",
              key: index,
              dangerouslySetInnerHTML: {
                __html: valueData[fieldName]
              }
            });
          }

          if (typeof valueData[fieldName] !== 'undefined' && fieldName === 'mark') {
            return _react["default"].createElement("i", {
              className: "tt-remarks",
              key: index,
              dangerouslySetInnerHTML: {
                __html: valueData[fieldName]
              }
            });
          }

          if (typeof valueData[fieldName] !== 'undefined') {
            return _react["default"].createElement("span", {
              key: index,
              dangerouslySetInnerHTML: {
                __html: valueData[fieldName]
              }
            });
          }
        }));
      }
    } else {
      if (!html) {
        result = _react["default"].createElement("span", null, label);
      } else {
        result = _react["default"].createElement("span", {
          dangerouslySetInnerHTML: {
            __html: label
          }
        });
      }
    }

    return result;
  };

  var label = '';
  var title = '';
  var className = '';
  var setValueLabelData = {};
  var currentValueData = (0, _index.getCurrentDataForValueHolder)(data, labelData, value); // 根据原始数据获取下label, title

  if (typeof currentValueData === 'number' || currentValueData) {
    label = getValueLabel(value);
    title = _typeof(currentValueData) !== 'object' ? currentValueData : currentValueData[labelFieldName] || '';
  } else {
    label = placeholder;
    className = 'placeholder';
  } // 如果有setValueLabel


  if ((0, _spauiUtils.isFunction)(setValueLabel) && label !== placeholder) {
    setValueLabelData = setValueLabel(data);

    if (_typeof(setValueLabelData) === 'object' && setValueLabelData && setValueLabelData.$$typeof) {
      label = setValueLabelData;
    } else if (_typeof(setValueLabelData) === 'object' && setValueLabelData) {
      label = setValueLabelData[labelFieldName];
      title = setValueLabelData.title || '';
    } else {
      label = setValueLabelData;
      title = setValueLabelData;
    }
  }

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(classNameMap.SelectValueHolderClassName, className, valueAreaClassName, (_classNames = {}, _defineProperty(_classNames, classNameMap.SelectValueHolderClearClassName, showClearIcon), _defineProperty(_classNames, "placeholder", !(typeof currentValueData === 'number' || currentValueData)), _classNames)),
    onClick: onValueAreaClick,
    style: valueAreaStyle
  }, valuePrefix, _react["default"].createElement("span", {
    className: "spaui-select-valueholder-width-icon",
    title: title
  }, _typeof(data) === 'object' && _typeof(currentValueData) === 'object' && currentValueData.img ? _react["default"].createElement("img", {
    src: currentValueData.img
  }) : null, _react["default"].createElement("span", null, label)), valueSuffix, showClearIcon ? _react["default"].createElement("div", {
    className: "spaui-select-clear",
    role: "button",
    onClick: onClear
  }, _react["default"].createElement("svg", {
    viewBox: "0 0 16 16",
    width: "16px",
    height: "16px"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    fillRule: "evenodd",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M5.333 5.333l5.334 5.334M10.667 5.333l-5.334 5.334",
    stroke: "#FFF",
    strokeLinecap: "square"
  }))) : null, arrowDom, valueAddons);
};

exports["default"] = _default;