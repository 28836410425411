"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _pdfobject = require("../../utils/pdfobject");

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var BottomComponent = function BottomComponent(props) {
  var toggleShowFoot = props.toggleShowFoot,
      data = props.data,
      propsIndex = props.index,
      onCurrent = props.onCurrent,
      onPrev = props.onPrev,
      onNext = props.onNext,
      htmlRef = props.htmlRef,
      total = props.total,
      TotalComponent = props.TotalComponent,
      SmallDisplayComponent = props.SmallDisplayComponent; // 目前显示的是第几张图

  var index = parseInt(propsIndex, 10) || 0;
  return _react["default"].createElement("div", {
    className: "viewer-bottom"
  }, _react["default"].createElement("div", {
    className: "viewer-bottom-toggle"
  }, _react["default"].createElement("i", {
    className: "viewer-icon viewer-icon-toggle",
    onClick: toggleShowFoot
  })), _react["default"].createElement("div", {
    className: "viewer-thumbs"
  }, _react["default"].createElement("div", {
    className: "viewer-thumbs-inner"
  }, _react["default"].createElement("ul", {
    className: "viewer-thumbs-pics",
    ref: htmlRef
  }, data.map(function (item, itemIndex) {
    return _react["default"].createElement("li", {
      key: itemIndex,
      title: (0, _index.getItemName)(item),
      className: (0, _classnames["default"])('small-pic-item', {
        current: itemIndex === index
      }),
      onClick: function onClick(event) {
        return onCurrent(event, itemIndex);
      }
    }, _react["default"].createElement(SmallDisplayComponent, {
      isPDF: (0, _pdfobject.isPDF)((0, _index.getItemThumbUrl)(item)),
      data: item
    }), _react["default"].createElement("div", {
      className: "viewer-pic-mask"
    }));
  }))), data && data.length > 1 ? // 往前切换图片按钮
  _react["default"].createElement("div", {
    className: "viewer-pre",
    onClick: onPrev
  }, _react["default"].createElement("i", {
    className: "viewer-icon"
  }, _react["default"].createElement("svg", {
    width: "24px",
    height: "24px",
    viewBox: "0 0 24 24",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    x: "0",
    y: "0",
    width: "20",
    height: "20"
  }), _react["default"].createElement("g", {
    transform: "translate(4.000000, 1.000000)",
    fill: "CurrentColor"
  }, _react["default"].createElement("polygon", {
    /* eslint-disable-next-line */
    transform: "translate(11.500000, 11.500000) scale(-1, 1) rotate(45.000000) translate(-11.500000, -11.500000) ",
    points: "19 4 19 6 19 19 17 19 17 6 4 6 4 4 17 4"
  })))))) : null, data && data.length > 1 ? // 往后切换图片按钮
  _react["default"].createElement("div", {
    className: "viewer-next",
    onClick: onNext
  }, _react["default"].createElement("i", {
    className: "viewer-icon"
  }, _react["default"].createElement("svg", {
    width: "24px",
    height: "24px",
    viewBox: "0 0 24 24",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink"
  }, _react["default"].createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react["default"].createElement("rect", {
    x: "0",
    y: "0",
    width: "20",
    height: "20"
  }), _react["default"].createElement("g", {
    /* eslint-disable-next-line */
    transform: "translate(8.500000, 12.500000) scale(-1, 1) translate(-8.500000, -12.500000) translate(-3.000000, 1.000000)",
    fill: "CurrentColor"
  }, _react["default"].createElement("polygon", {
    /* eslint-disable-next-line */
    transform: "translate(11.500000, 11.500000) scale(-1, 1) rotate(45.000000) translate(-11.500000, -11.500000) ",
    points: "19 4 19 6 19 19 17 19 17 6 4 6 4 4 17 4"
  })))))) : null), total && data.length > 0 ? // 总计结构
  _react["default"].createElement(TotalComponent, _extends({}, props, {
    totalable: total,
    index: index,
    data: data
  })) : null);
};

var _default = BottomComponent;
exports["default"] = _default;