"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2025, a Tencent company. All rights reserved.
 */
var _default = {
  componentName: 'spaui-tabs',
  componentVersion: '1.0.249-beta.0',
  name: 'spaui-tabs',
  version: '1.0.249-beta.0'
};
exports["default"] = _default;