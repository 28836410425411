"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDataSet = void 0;

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 获取操作事件map
var getDataSet = function getDataSet() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var result = {};
  var key = '';

  if (_typeof(data) === 'object' && data) {
    for (key in data) {
      if (typeof key.indexOf !== 'undefined' && key.indexOf('data-') === 0) {
        result[key] = data[key];
      }
    }
  }

  return result;
};

exports.getDataSet = getDataSet;