"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _spauiUtils = require("@tencent/spaui-utils");

var _index = require("../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var _default = function _default(props) {
  var html = props.html,
      separator = props.separator,
      multiple = props.multiple,
      _props$valueAreaStyle = props.valueAreaStyle,
      valueAreaStyle = _props$valueAreaStyle === void 0 ? {} : _props$valueAreaStyle,
      _props$valueAreaClass = props.valueAreaClassName,
      valueAreaClassName = _props$valueAreaClass === void 0 ? '' : _props$valueAreaClass,
      propsValueLabelField = props.valueLabelField,
      placeholder = props.placeholder,
      value = props.value,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      setValueLabel = props.setValueLabel,
      _props$labelFieldName = props.labelFieldName,
      labelFieldName = _props$labelFieldName === void 0 ? '' : _props$labelFieldName,
      _props$classNameMap = props.classNameMap,
      classNameMap = _props$classNameMap === void 0 ? {} : _props$classNameMap,
      valuePrefix = props.valuePrefix,
      valueSuffix = props.valueSuffix,
      valueAddons = props.valueAddons;

  var _getTheContentOfTheVa = (0, _index.getTheContentOfTheValueAreaComponent)(props),
      onClear = _getTheContentOfTheVa.onClear,
      arrowDom = _getTheContentOfTheVa.arrowDom,
      onValueAreaClick = _getTheContentOfTheVa.onValueAreaClick,
      showClearIcon = _getTheContentOfTheVa.showClearIcon;

  var valueLabelField = typeof propsValueLabelField === 'undefined' ? [labelFieldName, 'mark', 'description'] : propsValueLabelField; // 选项数据结构为对象

  var getValueLabel = function getValueLabel(currentValue) {
    var valueData = _typeof(data[currentValue]) !== 'object' && typeof data[currentValue] !== 'undefined' ? data[currentValue] : data[currentValue] || {};
    var haveLabel = typeof valueData[labelFieldName] !== 'undefined';
    var label = haveLabel ? valueData[labelFieldName] : _typeof(valueData) !== 'object' && typeof valueData !== 'undefined' ? valueData : '';
    var result = ''; // 选项数据结构是对象，并且存在文案

    if (_typeof(valueData) === 'object' && haveLabel) {
      if (multiple) {
        // 多选就只是-分隔
        result = [];
        valueLabelField.forEach(function (fieldName) {
          if (typeof valueData[fieldName] !== 'undefined') {
            if (_typeof(valueData[fieldName]) === 'object' && valueData[fieldName] && valueData[fieldName].props && valueData[fieldName].$$typeof) {
              result.push(valueData[fieldName]);
            } else {
              result.push(String(valueData[fieldName]));
            }
          }
        });

        if (result.length > 1) {
          result = result.join('-');
        }
      } else {
        // 单选的是否要进行html
        if (!html) {
          result = _react["default"].createElement("div", {
            className: "spaui-selection-item tt"
          }, valueLabelField.map(function (fieldName, index) {
            if (typeof valueData[fieldName] !== 'undefined' && fieldName === labelFieldName) {
              return _react["default"].createElement("div", {
                className: "spaui-selection-item-content in",
                key: index
              }, valueData[fieldName]);
            }

            if (typeof valueData[fieldName] !== 'undefined' && fieldName === 'mark') {
              return _react["default"].createElement("i", {
                className: "tt-remarks",
                key: index
              }, valueData[fieldName]);
            }

            if (typeof valueData[fieldName] !== 'undefined') {
              return _react["default"].createElement("div", {
                key: index
              }, valueData[fieldName]);
            }
          }));
        } else {
          result = _react["default"].createElement("div", {
            className: "spaui-selection-item tt"
          }, valueLabelField.map(function (fieldName, index) {
            if (typeof valueData[fieldName] !== 'undefined' && fieldName === labelFieldName) {
              return _react["default"].createElement("div", {
                className: "spaui-selection-item-content in",
                key: index,
                dangerouslySetInnerHTML: {
                  __html: valueData[fieldName]
                }
              });
            }

            if (typeof valueData[fieldName] !== 'undefined' && fieldName === 'mark') {
              return _react["default"].createElement("i", {
                className: "tt-remarks",
                key: index,
                dangerouslySetInnerHTML: {
                  __html: valueData[fieldName]
                }
              });
            }

            if (typeof valueData[fieldName] !== 'undefined') {
              return _react["default"].createElement("div", {
                key: index,
                dangerouslySetInnerHTML: {
                  __html: valueData[fieldName]
                }
              });
            }
          }));
        }
      }
    } else {
      if (multiple) {
        result = label;
      } else {
        if (!html) {
          result = _react["default"].createElement("span", null, label);
        } else {
          result = _react["default"].createElement("span", {
            dangerouslySetInnerHTML: {
              __html: label
            }
          });
        }
      }
    }

    return result;
  };

  var label = '';
  var showLabel = '';
  var title = '';
  var className = '';
  var setValueLabelData = {}; // 根据原始数据获取下label, title

  if (multiple) {
    if (value.length < 1) {
      label = placeholder;
      className = 'placeholder';
    } else {
      label = [];
      value.map(function (item) {
        label.push(getValueLabel(item));
        return item;
      });

      if (label.length > 1) {
        title = label.join(separator);
        showLabel = label.join(separator);
      } else {
        title = label[0] || '';
        showLabel = label[0] || '';
      }

      if (!html) {
        label = _react["default"].createElement("span", null, showLabel);
      } else {
        label = _react["default"].createElement("span", {
          dangerouslySetInnerHTML: {
            __html: showLabel
          }
        });
      }
    }
  } else {
    if (typeof data[value] === 'number' || data[value]) {
      label = getValueLabel(value);
      title = _typeof(data[value]) !== 'object' ? data[value] : data[value][labelFieldName] || '';
    } else {
      label = placeholder;
      className = 'placeholder';
    }
  } // 如果有setValueLabel


  if ((0, _spauiUtils.isFunction)(setValueLabel) && label !== placeholder) {
    setValueLabelData = setValueLabel(data);

    if (_typeof(setValueLabelData) === 'object' && setValueLabelData && setValueLabelData.$$typeof) {
      label = setValueLabelData;
    } else if (_typeof(setValueLabelData) === 'object' && setValueLabelData) {
      label = setValueLabelData[labelFieldName];
      title = setValueLabelData.title || '';
    } else {
      label = setValueLabelData;
      title = setValueLabelData;
    }
  }

  var setPlaceholderHtml = function setPlaceholderHtml(labelElement) {
    var result = labelElement;

    if (labelElement && typeof labelElement === 'string') {
      result = _react["default"].createElement("div", {
        className: "spaui-selection-item tt"
      }, _react["default"].createElement("div", {
        className: "spaui-selection-item-content in"
      }, labelElement));
    }

    return result;
  };

  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(classNameMap.SelectValueHolderClassName, className, valueAreaClassName, _defineProperty({}, classNameMap.SelectValueHolderClearClassName, showClearIcon)),
    onClick: onValueAreaClick,
    style: valueAreaStyle,
    tabIndex: "0"
  }, valuePrefix, _react["default"].createElement("div", {
    title: title,
    className: "selection-single-text"
  }, setPlaceholderHtml(label)), valueSuffix, showClearIcon ? _react["default"].createElement("div", {
    className: "spaui-select-clear",
    role: "button",
    onClick: onClear
  }, _react["default"].createElement("svg", {
    viewBox: "0 0 16 16",
    width: "16px",
    height: "16px"
  }, _react["default"].createElement("circle", {
    fill: "currentColor",
    fillRule: "evenodd",
    cx: "8",
    cy: "8",
    r: "8"
  }), _react["default"].createElement("path", {
    d: "M5.333 5.333l5.334 5.334M10.667 5.333l-5.334 5.334",
    stroke: "#FFF",
    strokeLinecap: "square"
  }))) : null, arrowDom, valueAddons);
};

exports["default"] = _default;