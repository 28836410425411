"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiRadio = _interopRequireDefault(require("@tencent/spaui-radio"));

var _spauiCheckbox = _interopRequireDefault(require("@tencent/spaui-checkbox"));

var _spauiUtils = require("@tencent/spaui-utils");

var _spauiPopover = require("@tencent/spaui-popover");

var _classnames = _interopRequireDefault(require("classnames"));

var _index = require("../utils/index");

var _index2 = require("../config/index");

var _table_compact_props = _interopRequireDefault(require("../table_compact_props"));

var _get_node = require("../utils/get_node");

var _get_data = require("../utils/get_data");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

// 多选勾选回调
var onCheckboxChange = function onCheckboxChange(event, value, propsValue, onChange) {
  var trData = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var dataMap = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
  var result = [];
  var index = -1; // 复制下props的数据

  propsValue.map(function (item) {
    result.push(item);
    return item;
  });
  index = (0, _spauiUtils.indexOf)(result, value); // 如果勾选的值已经选中了，那就是反选去掉

  if (index > -1) {
    result.splice(index, 1);
  } else {
    result.push(value);
  }

  if ((0, _spauiUtils.isFunction)(onChange)) {
    onChange(event, result, trData, dataMap, 'body');
  }
}; // 获取可选列单元格的内容


var getTheContentOfOptionalCell = function getTheContentOfOptionalCell() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var otherProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var tableData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var name = props.name,
      multiple = props.multiple,
      target = props.target,
      propsValue = props.value,
      onOptionalChange = props.onOptionalChange,
      disabledValue = props.disabledValue,
      render = props.render,
      max = props.max,
      canOptional = props.canOptional,
      CustomTdComponent = props.component,
      setChecked = props.setChecked,
      setIndeterminate = props.setIndeterminate,
      customScope = props.customScope,
      _props$disabledValueC = props.disabledValueConfig,
      disabledValueConfig = _props$disabledValueC === void 0 ? {} : _props$disabledValueC;
  var _otherProps$CheckboxP = otherProps.CheckboxProps,
      CheckboxProps = _otherProps$CheckboxP === void 0 ? {} : _otherProps$CheckboxP,
      _otherProps$RadioProp = otherProps.RadioProps,
      RadioProps = _otherProps$RadioProp === void 0 ? {} : _otherProps$RadioProp,
      trType = otherProps.trType,
      index = otherProps.index,
      _optional = otherProps._optional,
      _otherProps$loading = otherProps.loading,
      loading = _otherProps$loading === void 0 ? false : _otherProps$loading;
  var result = null;
  var className = 'spaui-table-optional-input';
  var value = '';
  var checked = false;
  var disabled = false;
  var useCustomTdComponent = false;
  var overlayTriggerProps = {}; // 选项对应的值是哪个

  if (!(0, _spauiUtils.isUndefined)(data[target])) {
    value = data[target];
  } else if (target === '_index') {
    // 如果taget是_index，说明要的是索引位置
    value = index;
  } // 是否禁选


  disabled = (0, _spauiUtils.indexOf)(disabledValue, value) > -1; // 没有明确指明是不可选的
  // 扩展数据是没得选的

  if (typeof _optional === 'undefined' || _optional) {
    if (multiple) {
      var dataMap = (0, _index.genDataMap)(target, tableData, [].concat(propsValue, value)); // 是否选中了

      if ((0, _spauiUtils.isFunction)(setChecked)) {
        checked = setChecked({
          value: value,
          data: data,
          dataMap: dataMap
        });
      } else {
        checked = (0, _spauiUtils.indexOf)(propsValue, value) > -1;
      } // 指定的数量，如果选中的数据到达了数量，对于没有选中的就都要disabled了


      if (typeof max !== 'undefined' && max > 0) {
        if (max <= propsValue.length && !checked) {
          disabled = true;
        }
      }

      if ((0, _spauiUtils.isFunction)(canOptional)) {
        disabled = !canOptional(data, disabled, loading);
      } // 自定义是否是半选状态


      var indeterminate = (0, _spauiUtils.isFunction)(setIndeterminate) ? setIndeterminate({
        value: value,
        data: data,
        dataMap: dataMap
      }) : false;
      var checkboxClassName = '';

      if ((0, _spauiUtils.isFunction)(CheckboxProps.className)) {
        checkboxClassName = CheckboxProps.className({
          value: value,
          data: data,
          dataMap: dataMap
        });
      } else {
        checkboxClassName = CheckboxProps.className || '';
      }

      className = (0, _classnames["default"])(className, 'spaui-checkbox', checkboxClassName, {
        'pre-check': indeterminate
      });
      result = _react["default"].createElement(_spauiCheckbox["default"], {
        "data-optional": "true",
        onClick: CheckboxProps.onClick,
        className: className,
        value: value,
        checked: checked,
        indeterminate: indeterminate,
        onChange: function onChange(event) {
          onCheckboxChange(event, value, propsValue, onOptionalChange, data, dataMap);
        },
        disabled: disabled
      }); // result = (
      //     <label data-optional="true" className={className} onClick={CheckboxProps.onClick}>
      //         <input
      //             type="checkbox"
      //             className="check"
      //             value={value}
      //             checked={checked}
      //             indeterminate={indeterminate.toString()}
      //             onChange={(event) =>
      //                 onCheckboxChange(event, value, propsValue, onOptionalChange, data, dataMap)
      //             }
      //             disabled={disabled}
      //         />
      //         <span className="ico"></span>
      //     </label>
      // );
    } else {
      className = "".concat(className, " ").concat(RadioProps.className || '');

      if ((0, _spauiUtils.isFunction)(canOptional)) {
        disabled = !canOptional(data, disabled, loading);
      }

      result = _react["default"].createElement(_spauiRadio["default"], _extends({}, RadioProps, {
        "data-optional": true // 用来标记这是一个可选按钮
        ,
        value: value,
        label: "",
        checked: value === propsValue,
        onChange: function onChange(event, selectedValue) {
          var dataMap = (0, _index.genDataMap)(target, tableData, selectedValue);

          if ((0, _spauiUtils.isFunction)(onOptionalChange)) {
            onOptionalChange(event, selectedValue, data, dataMap, 'body');
          }
        },
        disabled: disabled,
        className: className
      }));
    }

    if (disabled && disabledValueConfig[value]) {
      overlayTriggerProps = {
        trigger: 'hover',
        hideDelayTime: 0
      };

      if (_typeof(disabledValueConfig[value]) === 'object' && !disabledValueConfig[value].props) {
        overlayTriggerProps = _objectSpread({}, overlayTriggerProps, {}, disabledValueConfig[value]);
      } else {
        overlayTriggerProps.overlay = _react["default"].createElement(_spauiPopover.PopOver, null, disabledValueConfig[value]);
      }

      result = _react["default"].createElement(_spauiPopover.OverlayTrigger, overlayTriggerProps, result);
    }

    if (typeof CustomTdComponent !== 'undefined') {
      // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
      if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
        if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
          useCustomTdComponent = true;
        }
      } else {
        useCustomTdComponent = true;
      }

      if (useCustomTdComponent) {
        if ((0, _spauiUtils.isReactNode)(CustomTdComponent)) {
          result = CustomTdComponent;
        } else {
          result = _react["default"].createElement(CustomTdComponent, _extends({}, otherProps, props, {
            trType: "",
            checkedValue: props.value,
            type: trType,
            name: name,
            value: result,
            data: data,
            disabled: disabled,
            rowIndex: otherProps.index,
            colIndex: otherProps.colIndex
          }));
        }
      }
    } else if (render && (0, _spauiUtils.isFunction)(render)) {
      // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
      if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
        if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
          result = render(trType, name, result, data, propsValue, disabled, otherProps.index);
        }
      } else {
        // 没有指定作用域的，全部都是render
        result = render(trType, name, result, data, propsValue, disabled, otherProps.index);
      }
    }
  }

  return result;
}; // 获取不可选的单元格的内容


var getTheContentOfUnOptionalCell = function getTheContentOfUnOptionalCell() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var otherProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var name = props.name,
      format = props.format,
      render = props.render,
      undefinedLabel = props.undefinedLabel,
      supportingHierarchicalData = props.supportingHierarchicalData,
      CustomTdComponent = props.component,
      customScope = props.customScope;
  var trType = otherProps.trType;
  var originValue = (0, _get_data.getValueByName)(data, name, supportingHierarchicalData);
  var value = typeof originValue === 'undefined' ? '' : originValue; // 没有指定component, render的默认显示方式

  var defaultResult = function defaultResult() {
    var useFormat = false; // 是否使用format的方式

    if (format) {
      // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
      if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
        if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
          useFormat = true;
        }
      } else {
        // 没有指定作用域的，全部都是render
        useFormat = true;
      }
    }

    if (useFormat) {
      // 指定了format可以替换{value}
      result = format.replace(/\$\{value\}/gi, value);
    } else {
      if (typeof originValue === 'undefined') {
        result = undefinedLabel;
      } else {
        if (value && _typeof(value) === 'object' && typeof value.$$typeof === 'undefined' && typeof value.props === 'undefined') {
          try {
            result = JSON.stringify(value);
          } catch (e) {
            result = '';
          }
        } else {
          result = value;
        }
      }
    }
  };

  var result = null;
  var useCustomTdComponent = false;

  if (typeof CustomTdComponent !== 'undefined') {
    // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
    if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
      if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
        useCustomTdComponent = true;
      }
    } else {
      useCustomTdComponent = true;
    }

    if (useCustomTdComponent) {
      if ((0, _spauiUtils.isReactNode)(CustomTdComponent)) {
        result = CustomTdComponent;
      } else {
        result = _react["default"].createElement(CustomTdComponent, _extends({}, otherProps, props, {
          trType: "",
          checkedValue: props.value,
          type: trType,
          name: name,
          value: originValue,
          data: data,
          rowIndex: otherProps.index,
          colIndex: otherProps.colIndex
        }));
      }
    } else {
      defaultResult();
    }
  } else if (render && (0, _spauiUtils.isFunction)(render)) {
    // 如果指定了作用域，在作用域内的就用render处理下，不在作用域内的就用默认的方式
    if ((0, _spauiUtils.isArray)(customScope) && customScope.length > 0) {
      if ((0, _spauiUtils.indexOf)(customScope, trType) > -1) {
        result = render(trType, name, originValue, data, undefined, undefined, otherProps.index);
      } else {
        defaultResult();
      }
    } else {
      // 没有指定作用域的，全部都是render
      result = render(trType, name, originValue, data, undefined, undefined, otherProps.index);
    }
  } else {
    defaultResult();
  }

  return result;
}; // 获取对应行的类型


var getTrType = function getTrType() {
  var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  // 是否有对应的标记
  var _item$_expand = item._expand,
      _expand = _item$_expand === void 0 ? false : _item$_expand,
      _item$_title = item._title,
      _title = _item$_title === void 0 ? false : _item$_title,
      _item$_additional = item._additional,
      _additional = _item$_additional === void 0 ? false : _item$_additional,
      _item$_pushData = item._pushData,
      _pushData = _item$_pushData === void 0 ? false : _item$_pushData;

  var result = ''; // 行类型

  if (_expand) {
    result = 'expand';
  } else if (_title) {
    result = 'title';
  } else if (_additional) {
    result = 'additional';
  } else if (_pushData) {
    result = 'pushData';
  } else {
    result = 'data';
  }

  return result;
};

var TbodyComponent = function TbodyComponent() {
  var theProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var expandComponentFixedable = theProps.expandComponentFixedable,
      onClickTr = theProps.onClickTr,
      _theProps$showExpandC = theProps.showExpandComponent,
      showExpandComponent = _theProps$showExpandC === void 0 ? true : _theProps$showExpandC,
      _theProps$onMouseOver = theProps.onMouseOverTr,
      onMouseOverTr = _theProps$onMouseOver === void 0 ? _spauiUtils.noop : _theProps$onMouseOver,
      _theProps$onMouseOutT = theProps.onMouseOutTr,
      onMouseOutTr = _theProps$onMouseOutT === void 0 ? _spauiUtils.noop : _theProps$onMouseOutT,
      expandPlacement = theProps.expandPlacement,
      ExpandComponent = theProps.ExpandComponent,
      _theProps$expandCompo = theProps.expandComponentProps,
      expandComponentProps = _theProps$expandCompo === void 0 ? {} : _theProps$expandCompo,
      expandOptions = theProps.expandOptions,
      titleOptions = theProps.titleOptions,
      cellInnerClassName = theProps.cellInnerClassName,
      _theProps$children = theProps.children,
      children = _theProps$children === void 0 ? null : _theProps$children,
      propsData = theProps.data,
      filterName = theProps.filterName,
      filterValue = theProps.filterValue,
      htmlRef = theProps.htmlRef,
      tableWrap = theProps.tableWrap,
      _theProps$setTrClassN = theProps.setTrClassName,
      setTrClassName = _theProps$setTrClassN === void 0 ? _spauiUtils.noop : _theProps$setTrClassN,
      _theProps$CheckboxPro = theProps.CheckboxProps,
      CheckboxProps = _theProps$CheckboxPro === void 0 ? {} : _theProps$CheckboxPro,
      _theProps$RadioProps = theProps.RadioProps,
      RadioProps = _theProps$RadioProps === void 0 ? {} : _theProps$RadioProps,
      setCellStyle = theProps.setCellStyle,
      setCellTitle = theProps.setCellTitle,
      clickSpace = theProps.clickSpace,
      fixedTitle = theProps.fixedTitle,
      expandTrActiveClassName = theProps.expandTrActiveClassName,
      pushData = theProps.pushData,
      supportingHierarchicalData = theProps.supportingHierarchicalData,
      rowKey = theProps.rowKey,
      setOddEvenClassName = theProps.setOddEvenClassName,
      _theProps$additionalD = theProps.additionalDataStripeInherit,
      additionalDataStripeInherit = _theProps$additionalD === void 0 ? false : _theProps$additionalD,
      _theProps$expandCompo2 = theProps.expandComponentTdClassName,
      expandComponentTdClassName = _theProps$expandCompo2 === void 0 ? '' : _theProps$expandCompo2,
      _theProps$loading = theProps.loading,
      loading = _theProps$loading === void 0 ? false : _theProps$loading,
      DragComponent = theProps.DragComponent,
      onDrag = theProps.onDrag;

  var _compactProps = (0, _table_compact_props["default"])(theProps),
      wholeRowIsOptional = _compactProps.wholeRowIsOptional; // 是否整行可选


  var columns = (0, _get_node.getColumns)(children);
  var data = (0, _get_data.getDataByFilterValue)(propsData, filterName, filterValue); // 根据筛选值来获得数据
  // 合并单元格的数据

  var rowAndColSpanData = (0, _get_data.getRowAndColSpanData)(theProps) || {};

  var _onClickTr = function _onClickTr(event, trType, trData, index) {
    var eventTarget = event.target;
    var optionalColumns = []; // 存储optional的Column，用于点击整行点时候，能够响应

    columns.map(function (item) {
      if (item && item.props && item.props.type === 'optional') {
        optionalColumns.push(item);
      }

      return item;
    }); // 点击整行且节点不是checkbox radio(checkbox radio有自己的onChange)

    if (wholeRowIsOptional && !eventTarget.parentNode.hasAttribute('data-optional')) {
      optionalColumns.map(function () {
        var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var _ref = item.props || {},
            multiple = _ref.multiple,
            target = _ref.target,
            propsValue = _ref.value,
            onOptionalChange = _ref.onOptionalChange,
            disabledValue = _ref.disabledValue,
            canOptional = _ref.canOptional,
            max = _ref.max,
            setChecked = _ref.setChecked;

        var value = ''; // 选中的数据值

        if (!(0, _spauiUtils.isUndefined)(trData[target])) {
          value = trData[target];
        } else if (target === '_index') {
          value = index;
        } // 是否禁止选中


        var disabled = false;
        var checked = false;
        disabled = (0, _spauiUtils.indexOf)(disabledValue, value) > -1;
        var dataMap = (0, _index.genDataMap)(target, propsData, [].concat(propsValue, value));

        if ((0, _spauiUtils.isFunction)(setChecked)) {
          checked = setChecked({
            value: value,
            data: data,
            dataMap: dataMap
          });
        } else {
          checked = (0, _spauiUtils.indexOf)(propsValue, value) > -1;
        } // 指定的数量，如果选中的数据到达了数量，对于没有选中的就都要disabled了, 且无法进行选中


        if (typeof max !== 'undefined' && max > 0) {
          if (max <= propsValue.length && !checked) {
            disabled = true;
          }
        }

        if ((0, _spauiUtils.isFunction)(canOptional)) {
          disabled = !canOptional(trData, disabled, loading);
        }

        if (!disabled) {
          if (multiple) {
            onCheckboxChange(event, value, propsValue, onOptionalChange, trData, dataMap);
          } else if ((0, _spauiUtils.isFunction)(onOptionalChange)) {
            onOptionalChange(event, value, trData, _defineProperty({}, value, trData), 'body');
          }
        }

        return item;
      });
    } // 没有拖动或者拖动的间距在一定范围内才触发点击行这个事件


    if (!eventClickPageX && !eventClickPageY || typeof event.pageX === 'undefined' && typeof event.pageY === 'undefined' || eventClickPageX - clickSpace <= event.pageX && eventClickPageX + clickSpace >= event.pageX && eventClickPageY - clickSpace <= event.pageY && eventClickPageY + clickSpace >= event.pageY) {
      var targetType = eventTarget.getAttribute('type');

      if ((0, _spauiUtils.isFunction)(onClickTr) && !(eventTarget.tagName === 'INPUT' && (targetType === 'checkbox' || targetType === 'radio'))) {
        onClickTr(event, trType, trData);
      }
    } // 响应点击的时候重置掉


    eventClickPageX = 0;
    eventClickPageY = 0;
  };

  var haveExapndOptionsData = [];
  var renderData = [];
  var renderDataByPushData = [];
  var expandIndex = -1;
  var expandData = {};
  var additionalExpandData = {};
  var eventClickPageX = 0;
  var eventClickPageY = 0;
  var showExpandComponentResult = showExpandComponent; // 如果需要二次展开的Component，实际上就是构造多一个tr，haveExapndOptionsData包含了扩展以及附加数据

  data.map(function (item, index) {
    // 有expandOptions的数据需要判断下
    if (expandOptions.length > 0) {
      // 对应的data数据，是否有expandOptions数据
      expandIndex = (0, _index.indexOfInExpandOptions)(expandOptions, item); // 构造一下扩展行的渲染数据

      if (expandIndex > -1) {
        expandData = (0, _spauiUtils.objectAssign)({
          _origindataindex: index,
          // 原始数据的行数
          _expand: true,
          // 是扩展行
          _expandType: 'byData',
          // 在原始表格数据的扩展
          _expandData: (0, _spauiUtils.objectAssign)(item),
          // 原始数据
          // expandOptions带的数据
          _additionalData: expandOptions[expandIndex]._additionalData || [],
          // expandOptions带的数据
          _expandComponentData: expandOptions[expandIndex]._expandComponentData || {}
        }, item); // 如果有指定show的话

        if (!(0, _spauiUtils.isUndefined)(expandOptions[expandIndex]._show)) {
          expandData._show = expandOptions[expandIndex]._show;
        }
      } // 可以指定展开的方向，top的话，展开行放在扩展数据上


      if (expandPlacement === 'top') {
        if (expandIndex > -1) {
          // 需要扩展的模块
          if (expandOptions[expandIndex]._additional) {
            // 如果带了_additionalData，也要加上renderData
            if ((0, _spauiUtils.isArray)(expandOptions[expandIndex]._additionalData)) {
              expandOptions[expandIndex]._additionalData.map(function (additionalItem) {
                haveExapndOptionsData.push((0, _spauiUtils.objectAssign)({
                  _origindataindex: index,
                  _additional: true,
                  _optional: expandOptions[expandIndex]._optional || false
                }, additionalItem)); // 扩展行的只接受函数的方式

                if ((0, _spauiUtils.isFunction)(showExpandComponent)) {
                  additionalExpandData = (0, _spauiUtils.objectAssign)({
                    _origindataindex: index,
                    _expand: true,
                    _expandType: 'byAdditionalData',
                    // 在附加数据的扩展
                    _expandData: (0, _spauiUtils.objectAssign)(item),
                    // 原始数据
                    // expandOptions带的数据
                    _additionalData: additionalItem,
                    // expandOptions带的数据
                    _expandComponentData: expandOptions[expandIndex]._expandComponentData || {}
                  }, additionalItem);
                  showExpandComponentResult = showExpandComponent(_objectSpread({}, expandComponentProps, {
                    type: additionalExpandData._expandType,
                    data: additionalExpandData._expandData,
                    additionalData: additionalExpandData._additionalData,
                    expandComponentData: additionalExpandData._expandComponentData
                  }));

                  if (showExpandComponentResult) {
                    // 在附加数据后添加多一个扩展行
                    haveExapndOptionsData.push(additionalExpandData);
                  }
                }

                return additionalItem;
              });
            }
          }

          if ((0, _spauiUtils.isFunction)(showExpandComponent)) {
            showExpandComponentResult = showExpandComponent();
          }

          if (showExpandComponentResult) {
            haveExapndOptionsData.push(expandData);
          }
        }

        haveExapndOptionsData.push((0, _spauiUtils.objectAssign)({
          _origindataindex: index,
          _isExpand: expandIndex > -1
        }, item));
      } else {
        haveExapndOptionsData.push((0, _spauiUtils.objectAssign)({
          _origindataindex: index,
          _isExpand: expandIndex > -1
        }, item));

        if (expandIndex > -1) {
          // 需要扩展的模块
          if (expandOptions[expandIndex]._additional) {
            // 如果带了_additionalData，也要加上renderData
            if ((0, _spauiUtils.isArray)(expandOptions[expandIndex]._additionalData)) {
              expandOptions[expandIndex]._additionalData.map(function (additionalItem) {
                haveExapndOptionsData.push((0, _spauiUtils.objectAssign)({
                  _origindataindex: index,
                  _additional: true,
                  _optional: expandOptions[expandIndex]._optional || false
                }, additionalItem)); // 扩展行的只接受函数的方式

                if ((0, _spauiUtils.isFunction)(showExpandComponent)) {
                  additionalExpandData = (0, _spauiUtils.objectAssign)({
                    _origindataindex: index,
                    _expand: true,
                    _expandType: 'byAdditionalData',
                    // 在附加数据的扩展
                    _expandData: (0, _spauiUtils.objectAssign)(item),
                    // 原始数据
                    // expandOptions带的数据
                    _additionalData: additionalItem,
                    // expandOptions带的数据
                    _expandComponentData: expandOptions[expandIndex]._expandComponentData || {}
                  }, additionalItem);
                  showExpandComponentResult = showExpandComponent(_objectSpread({}, expandComponentProps, {
                    type: additionalExpandData._expandType,
                    data: additionalExpandData._expandData,
                    additionalData: additionalExpandData._additionalData,
                    expandComponentData: additionalExpandData._expandComponentData
                  }));

                  if (showExpandComponentResult) {
                    // 在附加数据后添加多一个扩展行
                    haveExapndOptionsData.push(additionalExpandData);
                  }
                }

                return additionalItem;
              });
            }
          }

          if ((0, _spauiUtils.isFunction)(showExpandComponent)) {
            showExpandComponentResult = showExpandComponent(_objectSpread({}, expandComponentProps, {
              type: expandData._expandType,
              data: expandData._expandData,
              additionalData: expandData._additionalData,
              expandComponentData: expandData._expandComponentData
            }));
          }

          if (showExpandComponentResult) {
            haveExapndOptionsData.push(expandData);
          }
        }
      }
    } else {
      haveExapndOptionsData.push((0, _spauiUtils.objectAssign)({}, item));
    }

    return item;
  }); // 如果需要标题，实际上就是对应的行前构造多一个tr

  if (titleOptions.length > 0) {
    haveExapndOptionsData.map(function (item, index) {
      // 数据哪一个有标题行
      expandIndex = (0, _index.indexOfInTitleOptions)(titleOptions, item); // 不能是扩展行或者附加数据行

      if (expandIndex > -1 && !item._expand && !item._additional) {
        if (!fixedTitle || index !== 0) {
          renderData.push((0, _spauiUtils.objectAssign)({
            _origindataindex: index,
            _title: true,
            _titleData: titleOptions[expandIndex].data || {} // 标题行的数据

          }, item));
        }
      } // 添加下原来的数据


      renderData.push((0, _spauiUtils.objectAssign)({
        _origindataindex: typeof item._origindataindex !== 'undefined' ? item._origindataindex : index
      }, item));
      return item;
    });
  } else {
    renderData = haveExapndOptionsData;
  } // 添加扩展数据


  if ((0, _spauiUtils.isFunction)(pushData)) {
    // 先处理非pushData的数据，方便可以二次扩展数据
    renderData.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var pushDataList = [];
      var trType = '';

      var _props = item._props || {};

      if (item) {
        trType = getTrType(item);

        if (trType && trType !== 'pushData') {
          pushDataList = pushData(trType, item);

          if (!(0, _spauiUtils.isArray)(pushDataList)) {
            pushDataList = [];
          }
        }

        renderDataByPushData.push(_objectSpread({}, item, {
          _props: _objectSpread({}, _props, {
            className: (0, _classnames["default"])(_props.className || '', {
              'spaui-table-pushdata-active': pushDataList.length > 0
            })
          })
        }));
        pushDataList.map(function (pushDataItem, pushDataIndex) {
          if (_typeof(pushDataItem) === 'object') {
            _props = pushDataItem._props || {};
            renderDataByPushData.push(_objectSpread({}, pushDataItem, {
              _props: _objectSpread({}, _props, {
                className: (0, _classnames["default"])(_props.className || '', 'spaui-table-tr-pushData-firstlevel', {
                  'spaui-table-tr-pushData-first': pushDataIndex === 0,
                  'spaui-table-tr-pushData-last': pushDataIndex + 1 === pushDataList.length
                })
              }),
              _additional: false,
              _expand: false,
              _title: false,
              _pushData: true
            }));
          }

          return pushDataItem;
        });
      }

      return item;
    });
    renderData = renderDataByPushData; // 专门pushData的数据，方便可以二次扩展数据

    renderDataByPushData = [];
    renderData.map(function () {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var renderDataIndex = arguments.length > 1 ? arguments[1] : undefined;
      var pushDataList = [];
      var trType = '';
      var nextTrType = '';

      var _props = item._props || {};

      if (item) {
        trType = getTrType(item);

        if (_typeof(renderData[renderDataIndex + 1]) === 'object' && renderData[renderDataIndex + 1]) {
          nextTrType = getTrType(renderData[renderDataIndex + 1]);
        }

        if (trType && trType === 'pushData') {
          pushDataList = pushData(trType, item);

          if (!(0, _spauiUtils.isArray)(pushDataList)) {
            pushDataList = [];
          }
        }

        renderDataByPushData.push(_objectSpread({}, item, {
          _props: _objectSpread({}, _props, {
            className: (0, _classnames["default"])(_props.className || '', {
              'spaui-table-pushdata-active': pushDataList.length > 0
            })
          })
        }));

        if ((0, _spauiUtils.isArray)(pushDataList)) {
          pushDataList.map(function (pushDataItem, index) {
            if (_typeof(pushDataItem) === 'object') {
              _props = pushDataItem._props || {};
              renderDataByPushData.push(_objectSpread({}, pushDataItem, {
                _props: _objectSpread({}, _props, {
                  className: (0, _classnames["default"])(_props.className || '', 'spaui-table-pushdata-tr', {
                    'spaui-table-pushdata-tr-last': index + 1 === pushDataList.length,
                    'spaui-table-pushdata-tr-final': index + 1 === pushDataList.length && nextTrType !== 'pushData'
                  })
                }),
                _additional: false,
                _expand: false,
                _title: false,
                _pushData: true
              }));
            }

            return pushDataItem;
          });
        }
      }

      return item;
    });
    renderData = renderDataByPushData;
  } // 设置每一行的条纹索引（用于设置条纹样式）


  var oddEvenIndex = -1;
  renderData.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var trType = getTrType(item);

    if (trType === 'additional' && additionalDataStripeInherit) {
      item._oddEvenIndex = oddEvenIndex;
    } else if (trType === 'data') {
      oddEvenIndex = oddEvenIndex + 1;
      item._oddEvenIndex = oddEvenIndex;
    }

    return item;
  });
  return _react["default"].createElement("tbody", {
    ref: htmlRef
  }, (0, _get_node.getPreContent)(columns, theProps), renderData.map(function () {
    var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = arguments.length > 1 ? arguments[1] : undefined;
    // 原始数据行
    var origindataindex = typeof item._origindataindex !== 'undefined' ? item._origindataindex : index; // 对应这一行的合并单元格数据

    var dataRowAndColSpanData = rowAndColSpanData.data || [];
    var rowsRowAndColSpanData = dataRowAndColSpanData[origindataindex] || []; // 保留一下_optional的值，因为下面delete之后就获取不到了

    var _optional = item._optional; // 是否有对应的标记

    var _item$_expand2 = item._expand,
        _expand = _item$_expand2 === void 0 ? false : _item$_expand2,
        _item$_title2 = item._title,
        _title = _item$_title2 === void 0 ? false : _item$_title2,
        _item$_isExpand = item._isExpand,
        _isExpand = _item$_isExpand === void 0 ? false : _item$_isExpand,
        _show = item._show,
        _item$_props = item._props,
        _props = _item$_props === void 0 ? {} : _item$_props;

    var trType = getTrType(item);
    var expandComponentStyle = {};
    var tdListDom = null;
    var style = {};
    var expandComponentInstance = null;
    var needActiveClassName = false; // 条纹样式

    var oddEvenClassName = '';

    if (typeof item._oddEvenIndex !== 'undefined') {
      oddEvenClassName = setOddEvenClassName(item, item._oddEvenIndex);
    } // 去除私有属性


    delete item._origindataindex;
    delete item._additional;
    delete item._isExpand;
    delete item._optional;
    delete item._oddEvenIndex;

    if (_isExpand) {
      needActiveClassName = true; // 看看下一列是否对应这行的扩展，如果是的话，且_show为false那就说明不需要active class

      var nextRenderItem = renderData[index + 1];
      var nextRenderItemIsExit = _typeof(nextRenderItem) === 'object' && nextRenderItem;

      if (nextRenderItemIsExit && (0, _index.indexOfInExpandOptions)([nextRenderItem], item) === 0) {
        if (typeof nextRenderItem._show !== 'undefined' && !nextRenderItem._show) {
          needActiveClassName = false;
        }
      }
    } // 扩展的列


    if (_expand) {
      // 指定_show属性用于控制是否隐藏
      if (typeof _show !== 'undefined' && !_show) {
        style = {
          display: 'none'
        };
      } // 需要指定了组件


      if ((0, _spauiUtils.isFunction)(ExpandComponent)) {
        // 去除私有属性
        delete item._expand; // 如果指定了固定且有宽度，就给ExpandComponent设置下宽度

        var tableWrapRect = {};

        if (tableWrap && tableWrap.getBoundingClientRect()) {
          tableWrapRect = tableWrap.getBoundingClientRect();
        }

        if (expandComponentFixedable && tableWrapRect.width) {
          expandComponentStyle.width = "".concat(tableWrapRect.width - 16, "px");
        } // 根据附加组件类型来定义内容


        if (typeof ExpandComponent !== 'undefined') {
          if ((0, _spauiUtils.isReactNode)(ExpandComponent)) {
            expandComponentInstance = ExpandComponent;
          } else {
            expandComponentInstance = _react["default"].createElement(ExpandComponent, _extends({}, expandComponentProps, {
              type: item._expandType,
              data: item._expandData,
              additionalData: item._additionalData,
              expandComponentData: item._expandComponentData
            }));
          }
        }

        tdListDom = _react["default"].createElement("td", {
          colSpan: columns.length,
          className: "spaui-table-expandcomponent ".concat(expandComponentTdClassName),
          "data-fixed": expandComponentFixedable ? 1 : '',
          "data-index": 0
        }, _react["default"].createElement("div", {
          className: cellInnerClassName,
          style: expandComponentStyle
        }, expandComponentInstance));
        delete item._expandData;
        delete item._additionalData;
        delete item._expandComponentData;
      } else {
        // 如果ExpandComponent 没有的话，那就是空的
        tdListDom = null;
      }
    } else if (_title) {
      // 标题行
      // 去除私有属性
      delete item._title;

      if (item._titleData && item._titleData.title) {
        tdListDom = _react["default"].createElement("td", {
          colSpan: columns.length,
          className: "spaui-table-tr-title-td",
          "data-fixed": columns[0] && columns[0].props && columns[0].props.fixedable ? 1 : '',
          "data-index": 0
        }, _react["default"].createElement("div", {
          className: cellInnerClassName
        }, item._titleData.title));
      } else {
        tdListDom = null;
      }

      delete item._titleData;
    } else {
      tdListDom = columns.map(function () {
        var subItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        var colIndex = arguments.length > 1 ? arguments[1] : undefined;
        var _subItem$props = subItem.props,
            props = _subItem$props === void 0 ? {} : _subItem$props;
        var name = props.name,
            type = props.type,
            align = props.align,
            verticalAlign = props.verticalAlign,
            show = props.show,
            onClickTd = props.onClick,
            isFixed = props.isFixed,
            _props$fixedable = props.fixedable,
            propsFixedable = _props$fixedable === void 0 ? false : _props$fixedable,
            _props$fixedAlign = props.fixedAlign,
            fixedAlign = _props$fixedAlign === void 0 ? '' : _props$fixedAlign,
            _props$fixedBorder = props.fixedBorder,
            fixedBorder = _props$fixedBorder === void 0 ? true : _props$fixedBorder;
        var isFixedColumn = !(0, _spauiUtils.isUndefined)(isFixed) ? isFixed : propsFixedable;
        var cellTitle = ''; // 这个单元格的合并数据

        var rowAndColSpanProps = rowsRowAndColSpanData[colIndex] || {};
        var cellDom = null;
        var cellStyle = {};
        var customCellStyle = {}; // 可显示并且不是被合并了的单元格

        if (show && !(!(0, _spauiUtils.isUndefined)(rowAndColSpanProps.renderable) && !rowAndColSpanProps.renderable)) {
          // 单元格的style
          if (align) {
            cellStyle.textAlign = align;
          }

          if (verticalAlign) {
            cellStyle.verticalAlign = verticalAlign;
          }

          cellStyle = (0, _spauiUtils.objectAssign)(cellStyle, rowAndColSpanProps.style, _props.style || {});

          if ((0, _spauiUtils.isFunction)(setCellStyle)) {
            customCellStyle = setCellStyle(trType, name, typeof (0, _get_data.getValueByName)(item, name, supportingHierarchicalData) === 'undefined' ? '' : (0, _get_data.getValueByName)(item, name, supportingHierarchicalData), index, colIndex, item);

            if (_typeof(customCellStyle) === 'object') {
              cellStyle = (0, _spauiUtils.objectAssign)({}, cellStyle, customCellStyle);
            }
          }

          if ((0, _spauiUtils.isFunction)(setCellTitle)) {
            cellTitle = setCellTitle(trType, name, typeof (0, _get_data.getValueByName)(item, name, supportingHierarchicalData) === 'undefined' ? '' : (0, _get_data.getValueByName)(item, name, supportingHierarchicalData), index, colIndex, item, props, needActiveClassName);
          }

          cellDom = _react["default"].createElement("td", _extends({
            key: "spaui-table-tbody-td-".concat(name, "-").concat(index, "-").concat(colIndex)
          }, rowAndColSpanProps, {
            style: cellStyle,
            className: (0, _classnames["default"])(props.className, rowAndColSpanProps.className, props.dataClassName),
            onClick: onClickTd,
            title: cellTitle,
            "data-index": colIndex
          }), _react["default"].createElement("div", {
            className: cellInnerClassName
          }, type === 'optional' ? getTheContentOfOptionalCell(item, props, {
            CheckboxProps: CheckboxProps,
            RadioProps: RadioProps,
            trType: trType,
            index: index,
            colIndex: colIndex,
            _optional: _optional,
            loading: loading
          }, propsData) : getTheContentOfUnOptionalCell(item, _objectSpread({}, props, {
            supportingHierarchicalData: supportingHierarchicalData
          }), {
            trType: trType,
            index: index,
            colIndex: colIndex
          }), isFixedColumn ? _react["default"].createElement(_index2.FixedColumnBorder, {
            fixedAlign: fixedAlign,
            show: fixedBorder
          }) : null));
        }

        return cellDom;
      });
    } // 如果单元格没有内容，tr也就不需要了


    if (!tdListDom) {
      return null;
    }

    var trProps = {
      'data-spaui-table-tr': '1',
      key: "spaui-table-tbody-tr-".concat(index, "-").concat((0, _index.getRowKey)(item, rowKey)),
      className: (0, _classnames["default"])("spaui-table-tr-".concat(trType), setTrClassName(trType, item) || '', needActiveClassName ? expandTrActiveClassName : '', _props.className || '', oddEvenClassName),
      style: style,
      'data-index': origindataindex,
      onClick: function onClick(event) {
        return _onClickTr(event, trType, item, index);
      },
      onMouseOver: function onMouseOver(event) {
        return onMouseOverTr(event, trType, item);
      },
      onMouseOut: function onMouseOut(event) {
        return onMouseOutTr(event, trType, item);
      },
      onMouseDown: function onMouseDown(event) {
        eventClickPageX = event.pageX || 0;
        eventClickPageY = event.pageY || 0;
      }
    };

    if ((0, _spauiUtils.isFunction)(DragComponent)) {
      // 使用拖拽结构
      return _react["default"].createElement(DragComponent, {
        trProps: trProps,
        onDrag: onDrag,
        index: index,
        key: index
      }, tdListDom);
    }

    return _react["default"].createElement("tr", trProps, tdListDom);
  }));
};

var _default = TbodyComponent;
exports["default"] = _default;