"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件对应结构的默认className
 */
var _default = {
  Labels: 'spaui-labels',
  LabelClassName: 'spaui-labels-label',
  LabelsItemReadonlyClassName: 'readonly',
  LabelsClearClassName: 'spaui-labels-clear',
  LabelsActiveClassName: 'spaui-labels-focus-active'
};
exports["default"] = _default;