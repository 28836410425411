"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _expand_icon_component = _interopRequireDefault(require("./component/cascade_tree/expand_icon_component"));

var _title_component = _interopRequireDefault(require("./component/cascade_tree/title_component"));

var _checkbox_component = _interopRequireDefault(require("./component/cascade_tree/checkbox_component"));

var _radio_component = _interopRequireDefault(require("./component/cascade_tree/radio_component"));

var _sort_component = _interopRequireDefault(require("./component/sort_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * CascadeTree 接口参数
 * @alias CascadeTreeProps
 * @description 层叠树组件。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {array} data=[]] 树状的全部展示数据
 * @property {string} [valueType] 选中的节点
 * @property {array} value=[]] 选中数据（数组数据）
 * @property {func} onChange 回调函数*onChange = (event, value, valueTreeNodeMap)*
 * @property {bool} [multiple=true] 是否多选
 * @property {array} [open] 哪些选项展开
 * @property {func} [onOpen] 展开回调*onOpen = (event, open, nodeData, valueTreeNodeMap)*
 * @property {bool} [disabled=false] 是否禁用
 * @property {func} [isDisabledNode] 是否禁用节点<br />isDisabledNode = (treeNode, defaultAllowSelectd)
 * @property {bool} [parentNodeCanChoose=true] 父节点是否可选中（用于设置全部的父节点）
 * @property {func} [singleParentNodeCanChoose=null] 单独指定每个父节点是否可选中*singleParentNodeCanChoose=(value,valueTreeNodeMap)*
 * @property {string} [fieldNames={}] 原始数据字段映射
 * @property {func} [onClickItem] 点击节点回调*onClickItem=(event,data,type,nodeData,isNeedAsyncGetNodeData,curChecked)*
 * @property {func} [onScroll] ul滚动时触发*onScroll = (event, [offsetTop, offsetButtom], totalHeight)*
 * @property {number} [maxExpandOptionSize] 节点展开的最大数量
 * @property {number} [maxExpandStartLevel=5] 限制节点展开的最大数量从哪层开始
 * @property {bool} [isAutoCheckSubOption=true] 点击父节点是否选中或取消选中该节点下的子节点
 * @property {func} [loadingIds=[]] 正在加载数据的节点
 * @property {bool} [asyncGetNodeData=false] 是否异步获取节点数据
 * @property {titleline} multiple=true相关参数
 * @property {bool} [isAutoCheckedParent=true] 子节点全选时自动选上父节点, 并从checkedIds删除子节点(valueType=child时为false)
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {func} [onSort] 拖动排序回调
 * @property {titleline} multiple=false相关参数
 * @property {bool} [showRadio=false] 是否显示单选框*默认不显示*
 * @property {titleline} 自定义组件结构
 * @property {func} [ExpandIconComponent] 展开收起图标结构*(具体参数看案例)*
 * @property {func} [TitleComponent] 文案标题结构*(具体参数看案例)*
 * @property {func} [CheckboxComponent] 复选框结构*(具体参数看案例)*
 * @property {func} [setCheckboxProps] 设置复选框参数setCheckboxProps=(data)
 * @property {func} [setRadioProps] 设置单选框参数setRadioProps=(data)
 * @property {func} [IconComponent] 图标结构*(具体参数看案例)*
 * @property {func} [SortComponent] 排序结构*(具体参数看案例)
 * @property {titleline} 设置子级树结构的样式
 * @property {string} [nodeClassName] 设置节点 className
 * @property {string} [nodeChildClassName] 设置节点列表 className
 * @property {func} [setNodeTreeStyle] 设置根节点以下的节点数外层dom style
 * @property {func} [setNodeTreeClassName] 设置根节点以下的节点数外层dom className
 * @example
 * fieldNames={
 *      labelFieldName:'desc',
 *      titleFieldName: 'tips',
 *      nodeFieldName: 'options',
 *      disabledFieldName: 'disabled',
 *      valueFieldName:'value',
 *      expandFieldName: 'expand'
 * }
 *
 * &lt;ExpandIconComponent
    haveData={}
    open={open}
    onOpen={onClickExpand}
/&gt;
 *
 *&lt;TitleComponent
    className={className}
    onClick={onClickTitle}
    fieldNames={fieldNames}
    data={item}
/&gt;
 *
 *
 * &lt;CheckboxComponent
    onChange={(event) => {}}
    fieldNames={fieldNames}
    checked={selected}
    indeterminate={indeterminate}
    data={item}
    disabled={itemDisabled}
    onClick={onClickCheckbox}
/&gt;
 *
 *&lt;IconComponent
    data={item}
    selected={selected}
    disabled={itemDisabled}
    onChoose={(event) => {}}
    onOpen={(event) => {}}
/&gt;
 *
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: [],
    visualData: {
      type: 'array',
      description: '选项数据'
    }
  },
  value: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.array, _spauiUtils.PropTypes.number, _spauiUtils.PropTypes.string]).isRequired,
    defaultValue: [],
    visualData: {
      setType: function setType(stateData) {
        return stateData.multiple ? 'array' : 'input';
      },
      description: '选中值（多选时为数组）'
    }
  },
  asyncGetNodeData: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  loadingIds: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  open: {
    type: _spauiUtils.PropTypes.array
  },
  onOpen: {
    type: _spauiUtils.PropTypes.func
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    disabled: false
  },
  isDisabledNode: {
    type: _spauiUtils.PropTypes.func
  },
  parentNodeCanChoose: {
    type: _spauiUtils.PropTypes.bool,
    disabled: true
  },
  singleParentNodeCanChoose: {
    type: _spauiUtils.PropTypes.func
  },
  isAutoCheckedParent: {
    type: _spauiUtils.PropTypes.bool
  },
  fieldNames: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {
      labelFieldName: 'desc',
      titleFieldName: 'tips',
      nodeFieldName: 'options',
      disabledFieldName: 'disabled',
      valueFieldName: 'value',
      parentFieldName: 'parent_id',
      expandFieldName: 'expand'
    }
  },
  onClickItem: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _spauiUtils.noop
  },
  maxExpandOptionSize: {
    type: _spauiUtils.PropTypes.number
  },
  maxExpandStartLevel: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 5
  },
  isAutoCheckSubOption: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  ExpandIconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _expand_icon_component["default"]
  },
  TitleComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _title_component["default"]
  },
  CheckboxComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _checkbox_component["default"]
  },
  RadioComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _radio_component["default"]
  },
  IconComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  SortComponent: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _sort_component["default"]
  },
  onSort: {
    type: _spauiUtils.PropTypes.func
  },
  showRadio: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onScroll: {
    type: function type() {}
  },
  setNodeTreeStyle: {
    type: _spauiUtils.PropTypes.func
  },
  setNodeTreeClassName: {
    type: _spauiUtils.PropTypes.func
  },
  nodeClassName: {
    type: _spauiUtils.PropTypes.string
  },
  nodeChildClassName: {
    type: _spauiUtils.PropTypes.string
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;