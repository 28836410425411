"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _item_component = _interopRequireDefault(require("./component/item_component"));

var _loading_component = _interopRequireDefault(require("./component/loading_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2020, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * List 接口参数
 * @alias ListProps
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基础属性
 * @property {string} [baseClass] 基础类名
 * @property {string} [itemClassName] ListItem类名
 * @property {string} [bodyClassName] ListBody类名
 * @property {string} [itemActionsClassName] ListActios类名
 * @property {string|element} [header] 头内容
 * @property {string|element} [footer] 尾内容
 * @property {string|element} [ItemComponent] 自定义项组件
 * @property {object} [ItemComponentProps] 自定义项组件Props
 * @property {func} [ItemDataComponent] 自定义项数据组件
 * @property {object} [ItemDataComponentProps] 自定义项数据组件Props
 * @property {string|element} [LoadingComponent] 自定义Loading组件
 * @property {boolean} [bordered=false] 是否显示边框
 * @property {boolean} [hover=false] 是否包含hover态
 * @property {boolean} [loading=false] 是否开启loading态
 * @property {string} [loadingType='default'] loading类型*可选值：default, cover*
 * @property {string} [display=''] ListBody布局方式*可选值：inline, block, flex, grid, inline-flex, inline-grid*
 * @property {string} [overflow='auto'] ListBody溢出方式*可选值：auto, overlay, hidden*
 * @property {string|number} [gap] ListItem之间间隔，仅display="flex|grid"时有效
 * @property {string|number} [cols] List列数，仅display="grid"时有效
 * @property {string|number} [rows] List行数，仅display="grid"时有效
 * @property {string|number} [width] ListBody宽度
 * @property {string|number} [height] ListBody高度
 * @property {func} [onScroll] body区域滚动回调*onScroll = (event, [offsetTop, offsetButtom], totalHeight)*
 * @property {func|element} [EmptyDataComponent] 空数据组件
 * @property {titleline} ListItem参数
 * @property {string|number} [itemWidth] ListItem宽度
 * @property {string|number} [itemHeight] ListItem高度
 * @property {boolean} [itemBordered=false] ListItem是否显示边框
 * @property {string} [itemRadius='6px'] ListItem圆角,如6px
 * @property {boolean} [itemDivided=true] ListItem是否有分割线
 * @property {boolean} [showHelpOnHover=true] ListItem小问号是否在hover时显示
 * @property {boolean} [checkable=false] ListItem是否包含勾选组件
 * @property {string} [itemPadding='12px 24px'] ListItem内边距
 * @property {object} [itemStyle={}] ListItem 的 style
 * @property {array} [itemActions] 操作列表
 * @property {func} [ItemActionsComponent] 自定义操作项组件
 * @property {func} [onItemClick] 项点击事件
 * @property {titleline} 其他参数
 * @property {string} [bodyPadding='0'] ListBody内边距
 * @property {string} [headerPadding='12px 24px'] ListHeader内边距
 * @property {string} [footerPadding='12px 24px'] ListFooter内边距
 * @property {string|element|func} [ItemCheckComponent] 勾选组件
 * @property {object} [PopOverProps] PopOver参数
 * @property {object} [OverlayTriggerProps] OverlayTrigger参数
 * @property {string|element} [prefix] 前置内容
 * @property {string|element} [suffix] 后置内容
 * @property {array} [data] 数据*支持icon|disabled|prefix|suffix|extra|className|checked|status字段*
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  header: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: null
  },
  footer: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: null
  },
  prefix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: null
  },
  suffix: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: null
  },
  bordered: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  hover: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  baseClass: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-list'
  },
  itemClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-list-item'
  },
  itemActions: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.array, _spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  ItemComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _item_component["default"]
  },
  ItemComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  itemActionsClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'spaui-list-item-action'
  },
  bodyClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  loading: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  LoadingComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _loading_component["default"]
  },
  EmptyDataComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  onScroll: {
    type: _spauiUtils.PropTypes.func
  },
  ItemActionsComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: null
  },
  onItemClick: {
    type: _spauiUtils.PropTypes.func
  },
  ItemCheckComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element, _spauiUtils.PropTypes.string]),
    defaultValue: null
  },
  ItemDataComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func]),
    defaultValue: null
  },
  ItemDataComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  itemBordered: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  itemDivided: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  display: {
    type: _spauiUtils.PropTypes.oneOf(['inline', 'block', 'flex', 'grid', 'inline-flex', 'inline-grid']),
    defaultValue: 'block'
  },
  overflow: {
    type: _spauiUtils.PropTypes.oneOf(['auto', 'overlay', 'hidden']),
    "default": 'auto'
  },
  cols: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  rows: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  gap: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  width: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  height: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  itemRadius: {
    type: _spauiUtils.PropTypes.string
  },
  itemWidth: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  itemHeight: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.string, _spauiUtils.PropTypes.number])
  },
  itemStyle: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  loadingType: {
    type: _spauiUtils.PropTypes.oneOf(['default', 'cover']),
    "default": 'auto'
  },
  showHelpOnHover: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  checkable: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  itemPadding: {
    type: _spauiUtils.PropTypes.string
  },
  bodyPadding: {
    type: _spauiUtils.PropTypes.string
  },
  headerPadding: {
    type: _spauiUtils.PropTypes.string
  },
  footerPadding: {
    type: _spauiUtils.PropTypes.string
  },
  PopOverProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  OverlayTriggerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;