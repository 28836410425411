"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _index = require("./utils/index");

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * PageBar 接口参数
 * @alias PageBarProps
 * @description 用于点击页码切换浏览数据信息。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {titleline} 基本属性
 * @property {number} value=1 当前页码
 * @property {number} totalSize=0 总的数量（不是总页数哦）
 * @property {func} onChange 切换页码回调函数<br />onChange = (event, value, pageSize)
 * @property {number} [pageSize=10] 每页显示数量
 * @property {array} [pageSizeList=[]] 可切换的每页显示数量选择
 * @property {func} [onPageSizeChange] 切换每页页码数量回调<br />onPageSizeChange = (value, pageSize)
 * @property {string} [type='normal'] 页码类型*可选值：mini, normal*
 * @property {number} [buttonCount=8] 显示多个按钮
 * @property {func} [setButtonLabel] 设置页码文案<br />setButtonLabel = (type, value, active)
 * @property {string} [selectPlacement='auto'] 页码数量下拉框位置
 * @property {string} [selectAutoPlacementBasic='document'] 页码数量下拉框自动展开的依据*可选值：window（窗口）、document（文档流）*
 * @property {titleline} 隐藏显示对应模块属性
 * @property {bool} [showPageInfo=true] 是否显示页码信息
 * @property {bool} [showTotalSize=true] 显示共X条记录
 * @property {bool} [showTotalPage=true] 展示总页信息
 * @property {bool} [showTotalPageButton=true] type!=mini时当最后一页按钮前有省略时是否展示最后一页按钮
 * @property {bool} [showPageJudge=true] 展示页码跳转
 * @property {bool} [fixedShowPageSizeButton=false] 是否固定一直显示页码按钮
 * @property {titleline} 设置className属性
 * @property {string} [selectClassName=''] 页码下拉框className
 * @property {string} [buttonClassName=''] 按钮className
 * @property {func} [setButtonClassName] 设置页码按钮className<br/> setButtonClassName(type, value, active) 返回className字符串
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 *
 * @see
 *
 * onChange typings:
 *     (event: any, value: any, pageSize: any) => any
 *
 * ====
 *
 * onPageSizeChange typings:
 *     (value: any, pageSize: any) => any
 *
 * ====
 *
 * setButtonLabel typings:
 *     (type: any, value: any, active: any) => any
 *
 * ====
 *
 * setButtonClassName typings:
 *     (type: any, value: any, active: any) => any
 *
 * @example
 * setButtonClassName的type为：pageButton/prevPageButton/nextPageButton/totalPageButton/ellipsisButton
 * classNameMap配置可见于：
 * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-pagebar/src/js/config/classname_map.js
 */
var config = {
  value: {
    type: _spauiUtils.PropTypes.number.isRequired,
    defaultValue: 1
  },
  totalSize: {
    type: _spauiUtils.PropTypes.number.isRequired,
    defaultValue: 0
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  pageSize: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 10
  },
  pageSizeList: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: []
  },
  type: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'normal'
  },
  showPageInfo: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  showTotalSize: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  showTotalPage: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  showTotalPageButton: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  showPageJudge: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  show: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  buttonCount: {
    type: _spauiUtils.PropTypes.number,
    defaultValue: 8
  },
  selectPlacement: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'auto'
  },
  selectAutoPlacementBasic: {
    type: _spauiUtils.PropTypes.oneOf(['document', 'window']),
    defaultValue: 'document'
  },
  selectClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  buttonClassName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  setButtonLabel: {
    type: _spauiUtils.PropTypes.func,
    defaultValue: _index.setButtonLabel
  },
  setButtonClassName: {
    type: _spauiUtils.PropTypes.func
  },
  onPageSizeChange: {
    type: _spauiUtils.PropTypes.func
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;