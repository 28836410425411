"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _pdfobject = require("../../utils/pdfobject");

var _index = require("../../utils/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var PicComponent = function PicComponent(props) {
  var scale = props.scale,
      info = props.info,
      deg = props.deg,
      dragImage = props.dragImage,
      onMouseDown = props.onMouseDown,
      id = props.id,
      DisplayComponent = props.DisplayComponent,
      DisplayComponentProps = props.DisplayComponentProps,
      type = props.type;
  var isPDFFile = (0, _pdfobject.isPDF)((0, _index.getItemUrl)(info));
  var viewerPicEvent = {};
  var viewerPicStyle = {}; // 如果能够拖动图片的话，添加鼠标事件

  if (dragImage) {
    viewerPicEvent.onMouseDown = onMouseDown;
  } // 默认的缩放比例


  viewerPicStyle.transform = "scale(".concat(scale, ")"); // 如果是PDF设置下样式，且不能旋转

  if (isPDFFile) {
    viewerPicStyle = {
      width: '100%',
      height: '100%',
      transform: 'scale(1)'
    };
  }

  return _react["default"].createElement("div", {
    className: "viewer-pic ".concat(dragImage ? '' : 'viewer-pic-undrag'),
    style: viewerPicStyle
  }, isPDFFile ? _react["default"].createElement("div", {
    id: id,
    className: "viewer-pdf"
  }) : _react["default"].createElement(DisplayComponent, {
    type: type,
    className: "is-loaded",
    data: info,
    style: {
      transform: "rotate(".concat(deg, "deg)")
    },
    DisplayComponentProps: DisplayComponentProps
  }), dragImage && !isPDFFile ? _react["default"].createElement("div", _extends({
    style: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      cursor: 'move',
      transform: "rotate(".concat(deg, "deg)")
    }
  }, viewerPicEvent)) : null);
};

var _default = PicComponent;
exports["default"] = _default;