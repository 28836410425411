"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.defaultProps = exports.propTypes = exports.config = void 0;

var _spauiUtils = require("@tencent/spaui-utils");

var _clear_component = _interopRequireDefault(require("./component/clear_component"));

var _delete_component = _interopRequireDefault(require("./component/delete_component"));

var _item_component = _interopRequireDefault(require("./component/item_component"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Tencent is pleased to support the open source community by making SPA UI available.
 * Copyright (C) 2019, a Tencent company. All rights reserved.
 */

/**
 * @fileoverview 组件参数配置
 */

/**
 * Labels 接口参数
 * @alias LabelsProps
 * @description 进行标记和分类的标签。
 * @property {titleline} 默认支持参数
 * @property {blankline} className;style;id;data-*;accessKey;dir;draggable;tabIndex;title;htmlRef;
 * @property {string} [className=''] className='mini'的时候为小尺寸;
 * @property {titleline} 基础属性
 * @property {array} data=[]] 展示的数据
 * @property {func} [onChange] 切换状态回调函数*onChange = (event, data)   // data:值*
 * @property {bool} [multiple=true] 有使用activeData功能时，是否为多选
 * @property {array|string|number} [activeData] 处于选中的activeData
 * @property {string} [activeFieldName=''] active对应data中哪个字段（如果指定该字段,activeData的元素即为该字段的值，为空时元素时整个data元素）
 * @property {func} [onActiveDataChange] 选中的activeData回调
 * @property {bool} [disabled=false] 是否禁止
 * @property {bool} [readonly=false] 是否只读（不可清空/删除标签）
 * @property {string} [labelFieldName='label'] 	文案在data中对应的字段名
 * @property {string} [valueFieldName='value'] 值在data中对应的字段名
 * @property {func} [onDelete] 删除某项回调
 * @property {bool} [clear] 是否可清空*(旧名称为isClear)*
 * @property {bool} [hasSpan] 每一项是否要使用span元素包裹
 * @property {func} [onClear] 清空回调
 * @property {func} [setItemClassName] 设置对应数据的className*setItemClassName=(itemData, readonly) => return className*
 * @property {object} [OverlayTriggerProps={}] 使用tip的时候OverlayTrigger对应的参数
 * @property {object} [PopOverProps={}] 使用tip的时候PopOver对应的参数
 * @property {func} [onMouseEnter] 鼠标移入标签事件*(data, index) => {}*
 * @property {func} [onMouseLeave] 鼠标移出标签事件*(data, index) => {}*
 * @property {titleline} 自定义组件属性
 * @property {func|element} [ClearComponent] 清空组件*(具体参数看案例)*
 * @property {object} [ClearComponentProps={}] ClearComponent的参数
 * @property {func|element} [DeleteComponent] 清空组件*(具体参数看案例)*
 * @property {object} [DeleteComponentProps={}] DeleteComponent的参数
 * @property {element} [prefix] 前置内容
 * @property {func|element} [AddComponent] 附加组件*(具体参数看案例)*
 * @property {object} [AddComponentProps={}] AddComponent的参数
 * @property {func|element} [ItemComponent] 自定义项组件*(具体参数看案例)*
 * @property {object} [ItemComponentProps={}] ItemComponent的参数
 * @property {func} [tipRender] 根据数据内容自定义返回tip内容,返回null则不显示tips
 * @property {array} [componentSequence=['clear', 'add']] 自定义组件的顺序
 * @property {object} [classNameMap={}] 组件内对应结构的默认className(可以针对对应位置进行替换,具体参数见案例)
 * @property {object} [setNodeProps] 支持给特定dom node位置设置属性*(type, itemData) => object*
 * @property {string} [tagType='ul'] 自定义父元素标签
 * @see
 *
 * onChange typings:
 *     (event: any, data: any) => any
 *
 * ====
 *
 * onActiveDataChange typings:
 *     (event: any, value: any) => any
 *
 * ====
 *
 * onDelete typings:
 *     (event: any, deleteItem: any) => any
 *
 * ====
 *
 * onMouseEnter typings:
 *     (event: any, index: any) => any
 *
 * ====
 *
 * onMouseLeave typings:
 *     (event: any, index: any) => any
 *
 * ====
 *
 * tipRender typings:
 *     (props: any) => any
 *
 *
 * @example
 * &lt;ClearComponent
 key='clear'
    show={clearShow}
    onClick={onClear}
/&gt;
*
* &lt;DeleteComponent
  item={item}
  itemDeleteProps={itemDeleteProps}
  onDelete={onDelete}
/&gt;
    *
    * &lt;AddComponent
key='add'
/&gt;
/&gt;
    *
    * &lt;ItemComponent
label={label}
item={item}
classNameMap={classNameMap}
clearNode={clearNode}
tip={tip}
onDelete={onDelete}
/&gt;
 *
 * classNameMap配置可见于：
 * https://git.woa.com/ads/spaui-basic_ui_components/tree/master/src/spaui-labels/src/js/config/classname_map.js
 */
var config = {
  data: {
    type: _spauiUtils.PropTypes.array.isRequired,
    defaultValue: []
  },
  multiple: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  activeData: {
    type: _spauiUtils.PropTypes.any
  },
  onChange: {
    type: _spauiUtils.PropTypes.func
  },
  onActiveDataChange: {
    type: _spauiUtils.PropTypes.func
  },
  activeFieldName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: ''
  },
  disabled: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  valueFieldName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'value'
  },
  labelFieldName: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'label'
  },
  readonly: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: false
  },
  onDelete: {
    type: _spauiUtils.PropTypes.func
  },
  onClear: {
    type: _spauiUtils.PropTypes.func
  },
  clear: {
    type: _spauiUtils.PropTypes.bool,
    defaultValue: true
  },
  setItemClassName: {
    type: _spauiUtils.PropTypes.func
  },
  OverlayTriggerProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {
      trigger: 'hover',
      showDelayTime: 0,
      hideDelayTime: 0,
      placement: 'top'
    }
  },
  PopOverProps: {
    type: _spauiUtils.PropTypes.object
  },
  ClearComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _clear_component["default"]
  },
  ClearComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  DeleteComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _delete_component["default"]
  },
  DeleteComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  prefix: {
    type: _spauiUtils.PropTypes.element
  },
  AddComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element])
  },
  AddComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  ItemComponent: {
    type: _spauiUtils.PropTypes.oneOfType([_spauiUtils.PropTypes.func, _spauiUtils.PropTypes.element]),
    defaultValue: _item_component["default"]
  },
  ItemComponentProps: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  tipRender: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseEnter: {
    type: _spauiUtils.PropTypes.func
  },
  onMouseLeave: {
    type: _spauiUtils.PropTypes.func
  },
  componentSequence: {
    type: _spauiUtils.PropTypes.array,
    defaultValue: ['clear', 'add']
  },
  classNameMap: {
    type: _spauiUtils.PropTypes.object,
    defaultValue: {}
  },
  setNodeProps: {
    type: _spauiUtils.PropTypes.func
  },
  hasSpan: {
    type: _spauiUtils.PropTypes.bool
  },
  tagType: {
    type: _spauiUtils.PropTypes.string,
    defaultValue: 'ul'
  }
}; // 转化成PureComponent组件的配置

exports.config = config;
var configData = (0, _spauiUtils.formatProps)(config); // 导出

var propTypes = configData.propTypes;
exports.propTypes = propTypes;
var defaultProps = configData.defaultProps;
exports.defaultProps = defaultProps;
var _default = {
  config: config,
  propTypes: propTypes,
  defaultProps: defaultProps
};
exports["default"] = _default;