"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var HelpBlockComponent = function HelpBlockComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      _props$helpBlockClass = props.helpBlockClassName,
      helpBlockClassName = _props$helpBlockClass === void 0 ? '' : _props$helpBlockClass,
      _props$helpBlockStyle = props.helpBlockStyle,
      helpBlockStyle = _props$helpBlockStyle === void 0 ? {} : _props$helpBlockStyle;
  var childrenData = children; // 传object对象会白屏

  if (_typeof(children) === 'object' && !(0, _spauiUtils.isArray)(children) && children && !children.props && !children.$$typeof) {
    if ((0, _spauiUtils.isEmptyObject)(children)) {
      childrenData = '';
    } else {
      try {
        childrenData = JSON.stringify(children);
      } catch (e) {}
    }
  }

  return _react["default"].createElement("div", {
    className: "help-block ".concat(helpBlockClassName),
    style: helpBlockStyle
  }, childrenData);
};

var _default = HelpBlockComponent;
exports["default"] = _default;