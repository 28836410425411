"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiRadio = _interopRequireDefault(require("@tencent/spaui-radio"));

var _spauiUtils = require("@tencent/spaui-utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var RadioComponent = function RadioComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var checked = props.checked,
      _props$data = props.data,
      data = _props$data === void 0 ? {} : _props$data,
      onChange = props.onChange,
      _props$fieldNames = props.fieldNames,
      fieldNames = _props$fieldNames === void 0 ? {} : _props$fieldNames,
      _props$disabled = props.disabled,
      disabled = _props$disabled === void 0 ? false : _props$disabled,
      onClick = props.onClick,
      canCancelCheck = props.canCancelCheck,
      classNameMap = props.classNameMap,
      setRadioProps = props.setRadioProps;
  var _fieldNames$valueFiel = fieldNames.valueFieldName,
      valueFieldName = _fieldNames$valueFiel === void 0 ? 'desc' : _fieldNames$valueFiel;
  var radioProps = (0, _spauiUtils.isFunction)(setRadioProps) ? setRadioProps(_objectSpread({}, props, {
    setRadioProps: undefined
  })) : {};
  return _react["default"].createElement("span", {
    className: classNameMap.TreeRadio
  }, _react["default"].createElement(_spauiRadio["default"], _extends({
    onClick: onClick,
    onChange: onChange,
    checked: checked,
    disabled: disabled,
    value: data[valueFieldName],
    canCancelCheck: canCancelCheck
  }, radioProps || {})));
};

var _default = RadioComponent;
exports["default"] = _default;