"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiPortal = _interopRequireDefault(require("@tencent/spaui-portal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// 面板组件
var _default = function _default() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var children = props.children,
      mountTarget = props.mountTarget,
      _props$portalClassNam = props.portalClassName,
      portalClassName = _props$portalClassNam === void 0 ? '' : _props$portalClassNam,
      _props$containerClass = props.containerClassName,
      containerClassName = _props$containerClass === void 0 ? '' : _props$containerClass,
      _props$containerStyle = props.containerStyle,
      containerStyle = _props$containerStyle === void 0 ? {} : _props$containerStyle,
      _props$show = props.show,
      show = _props$show === void 0 ? true : _props$show,
      _props$portalStyle = props.portalStyle,
      portalStyle = _props$portalStyle === void 0 ? {} : _props$portalStyle,
      _props$userStyle = props.userStyle,
      userStyle = _props$userStyle === void 0 ? {} : _props$userStyle,
      mountTargetUseStyle = props.mountTargetUseStyle;
  var portalProps = {};
  var result = null;

  if (!show) {
    return null;
  } // 下拉面板结构，如果是挂载在body或者自定义结构，需要多加一层


  if (mountTarget === 'body' || _typeof(mountTarget) === 'object') {
    if (_typeof(mountTarget) === 'object') {
      portalProps.target = mountTarget;
    } // 如果需要从 Select 上面获取样式，需要加上 mountTargetUseStyle={true}


    var genStyle = portalStyle;

    if (mountTargetUseStyle) {
      genStyle = _objectSpread({}, userStyle, {}, portalStyle);
    }

    result = _react["default"].createElement(_spauiPortal["default"], _extends({
      className: "".concat(portalClassName, " spaui-select-portal")
    }, portalProps, {
      style: genStyle
    }), _react["default"].createElement("div", {
      className: containerClassName,
      style: _objectSpread({}, containerStyle)
    }, children));
  } else {
    result = children;
  }

  return result;
};

exports["default"] = _default;