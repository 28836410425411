"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _spauiPopover = require("@tencent/spaui-popover");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var ItemComponent = function ItemComponent() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var style = props.style,
      className = props.className,
      classNameMap = props.classNameMap,
      label = props.label,
      clearNode = props.clearNode,
      otherProps = props.otherProps,
      onClick = props.onClick,
      onMouseLeave = props.onMouseLeave,
      onMouseEnter = props.onMouseEnter,
      tip = props.tip,
      OverlayTriggerProps = props.OverlayTriggerProps,
      PopOverProps = props.PopOverProps,
      hasSpan = props.hasSpan;

  var dom = _react["default"].createElement("li", _extends({}, otherProps, {
    onClick: onClick,
    className: className,
    style: style,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave
  }), hasSpan ? _react["default"].createElement("span", {
    className: classNameMap.LabelClassName
  }, label) : label, clearNode);

  return !tip ? dom : _react["default"].createElement(_spauiPopover.OverlayTrigger, _extends({
    overlay: _react["default"].createElement(_spauiPopover.PopOver, PopOverProps, tip)
  }, OverlayTriggerProps), dom);
};

var _default = ItemComponent;
exports["default"] = _default;